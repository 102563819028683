/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image, ColumnCover, ColumnDiv, PriceWrap } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1b7odf6 --full pb--30 pt--30" name={"introduction"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/76/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="pb--60">
              
              <Title className="title-box w--400 title-box--invert ls--002" content={"Finch Street 24"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--invert mt--02" content={"NJ 07712"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"information"} style={{"backgroundColor":"var(--color-variable-1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--invert" content={"\"...things people do to escape from desires-because it's such a big responsibility, really to want something.\"\n"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Subtitle className="subtitle-box subtitle-box--invert" content={"Registration & Fees ... you too can create a website like this using <span style=\\text-decoration-line: underline; font-weight: bold; color: var(--color-variable-1);\\\">saywebpage.cz</span>\"\"\""}>
              </Subtitle>

              <Text className="text-box text-box--invert" style={{"maxWidth":430}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--cColor2 fs--24" href={"#pricelist"} content={"Find out more"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"information-2"}>
          
          <ColumnWrap className="column__flex el--3" style={{"gridColumnGap":"8%"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"No more than 8 children per class"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"More than 10 years of practice"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Multilingual<br>education"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"information-3"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--48 title-box--invert" content={"Creative activities"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--48 title-box--invert" content={"Modern facilities"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"pricelist"} style={{"backgroundColor":"var(--color-variable-2)"}}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="pr--40" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--invert" content={"Registration & Fees"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Kindergarten fees are paid upfront"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Registration for the 2020-2021 school year is open!"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--invert lh--14" content={"— Kindergarten fees are paid upfront"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert pb--20" content={"Morning program"}>
              </Subtitle>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 2 days a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 3 days a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--invert pb--20" content={"Whole day program"}>
              </Subtitle>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pl--10 pr--10" style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Attendance 1 day a week"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"$17"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"our-team"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Let's meet Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Teacher"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Let's meet Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Teacher"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Let's meet Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Teacher"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Let's meet Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Teacher"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Let's meet Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Teacher"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Let's meet Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Teacher"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Let's meet Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Teacher"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10">
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":200}} srcSet={"https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7806/4a816097e5e640e3ac4da478d02ed241_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"Let's meet Alena Nováková"}>
              </Subtitle>

              <Text className="text-box mt--02" content={"Teacher"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"photogallery"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --full --center el--4 pl--02 pr--0" columns={"4"}>
            
            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pb--02 pr--02">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/76/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/76/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/76/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/76/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/76/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"footer"}>
          
          <ColumnWrap className="column__flex el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kindergarten"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Contact"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"510-851-4014<br>info@vase-stranky.com<br>722 Finch Street<br>Asbury Park<br>NJ 07712"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Provozní doba"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Monday - Friday:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={""}>
              </Subtitle>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"Weekend:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={""}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Navigation"}>
              </Title>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName3]]'>Group discounts</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName6]]'>Our team</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName5]]'>Registration & Fees</a>"}>
              </Text>

              <Text className="text-box" style={{"color":"var(--color-variable-1)"}} content={"<a href='#[[T73SecName7]]'>Photogallery</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}